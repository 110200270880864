@import '@/components/variables.scss';

$x-www-column: 1400px;
$x-header-height: 72px;
$x-hero-height: 480px;

$x-iphone-height: 640px;

.layout {

  display: flex;
  flex-direction: column;
  @media screen and (max-width: $x-breakpoint-xs) {
    width: 100vw;
  }  

  &-centered {
    display: block;
    width: $x-www-column;
    margin: auto;
    @media screen and (max-width: $x-breakpoint-xs) {
      width: 100vw;
    }  
  }

  section {
    flex: 0 0 auto;
    padding: spacer(xxl) spacer(xl);
  }

  &-app {
    background-color: white;
    color: black;
  }

  &-primary {
    background-color: var(--primaryColor);
    color: white !important;
  }
  &-white {
    background-color: white;
  }
  &-offwhite {
    background-color: #F5F5F5;
  }

  &-column {
    display: block;
    width: $x-www-column;
    margin: auto;
    @media screen and (max-width: $x-breakpoint-xs) {
      width: 100%;
    }  
  }

  &-split {
    display: flex;
    flex-direction: row;
    width: 100%;
    @media screen and (max-width: $x-breakpoint-xs) {
      flex-direction: column;
      gap: 24px;
    }  
    > div {
      flex: 0 0 auto;
      width: 50%;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: flex-start;
      justify-content: center;
      padding: 44px 22px;
      position: relative;
      @media screen and (max-width: $x-breakpoint-xs) {
        align-items: center;
        padding: 0 22px 22px;
        width: 100%;
        gap: 12px;
      }  
    }
  }

  &-photos {
    flex: 0 0 auto;
    width: 100%;
    height: 500px;
    position: relative;
    @media screen and (max-width: $x-breakpoint-xs) {
      height: 40vh;
    }  
    &-over {
      &-1 {
        margin-left: 20%;
        transform: rotate(0deg);
        @media screen and (max-width: $x-breakpoint-xs) {
          margin-left: 20%;
        }  
      }
      &-2 {
        margin-left: 0%;
        transform: rotate(20deg);
        @media screen and (max-width: $x-breakpoint-xs) {
          margin-left: 0%;
        }  
      }
      &-3 {
        margin-left: -20%;
        transform: rotate(0deg);
        @media screen and (max-width: $x-breakpoint-xs) {
          margin-left: -20%;
        }  
      }
    }
  }

  &-text {
    width: 100%;
    font-size: 2rem;
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: 1.2rem;
      text-align: center;
    }
  }

  &-text-reg {
    width: 100%;
    font-size: 1.2rem;
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: 1rem;
      text-align: center;
    }
  }

  &-header {
    flex: 0 0 auto;
    font-size: 4rem;
    font-weight: 700;
    padding: 0 0 0 0;
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size:2.5rem;
      padding-top: 24px;
    }
  }

  &-iphone {
    flex: 0 0 auto;
    height: calc(70vh);
    width: calc(70vh * 1105/2261);
    position: relative;
    @media screen and (max-width: $x-breakpoint-xs) {
      height: calc(60vh);
      width: calc(60vh * 1105/2261);
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    video {
      display: block;
      position: absolute;
      z-index: 2;
      top: 2%;
      left: 4.5%;
      height: 96%;
      border-radius: calc(70vh*0.056);
      overflow: hidden;
      @media screen and (max-width: $x-breakpoint-xs) {
        border-radius: calc(60vh*0.056);
      }
    }
  }

  &-offer {
    flex: 0 0 auto;
    border-radius: 16px;
    padding: 18px 22px;
    border: 4px solid #FFFFFF;
    font-size: 1.3rem;
    // background-color: #FFFFFF;
    // color: var(--primaryColor);
    background-color: transparent;
    color: white;
    font-weight: 700;
  }

}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: spacer(xl);
  position: relative;
  height: calc($x-header-height + $x-hero-height);
  img {
    z-index: 0;
  }
  &-search {
    position: relative;
    flex: 0 0 auto;
    border-radius: var(--fieldBorderRadius);
    background-color: rgba(255,255,255,0.9);
    width: 640px;
    max-width: 100%;
    height: 64px;
    padding: 0 spacer(xl);
    font-size: 1rem;
  }
}

.header {

  display: block;
  position: relative;
  z-index: 1;
  // box-shadow: inset 0 150px 10px rgba(0,0,0,0.5);
  &-bar {
    display: block;
    width: $x-www-column;
    margin: auto;
    padding-left: spacer(xl);
    padding-right: spacer(xl);

    @media screen and (max-width: $x-breakpoint-xs) {
      width: 100vw;
    }  

    > div {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: $x-header-height;
      align-items: center;
      gap: spacer(xl);
      > div {
        flex: 0 0 auto;
      }
    }

    &-stretch {
      flex: 1 1 auto !important;
    }

    &-link {
      height: $x-header-height !important;
      line-height: $x-header-height !important;
      font-weight: 700 !important;
      text-transform: uppercase;
      color: var(--primaryColor) !important;
      text-decoration: none !important;
    }

  }

  &-logo {
    flex: 0 0 auto;
    img {
      display: block;
      height: 44px;
      @media screen and (max-width: $x-breakpoint-xs) {
        height: 32px;
      }  
    }
  }

}

.header-white {

  background-color: var(--primaryColor);
  .header-bar-link {
    color: var(--primaryText) !important;
  }

}

.home {

  &-top {
    &-bg {
      display: block;
      width: 100vw;
      // border-radius: calc(100vw * .3);
      // background-color: var(--primaryColor);
      // transform: rotate(33deg);
      // top: calc(-100vw * 2.1);
      // left: calc(-100vw * 1.5);
    }
  }

}

.body {
  display: block;
  width: $x-www-column;
  margin: auto;
  &-main {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding-top: $x-header-height;
    padding-bottom: $x-header-height;
    // min-height: calc(100vh - $x-header-height - 67px);
    align-items: center;
    justify-content: center;
    background-color: var(--primaryColor);
    &-images {
      flex: 0 0 auto;
    }
    &-messages {
      flex: 1 1 auto;
    }
    &-phones {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 24px;
      &-img {
        flex: 0 0 auto;
        position: relative;
        width: calc($x-iphone-height * 0.492);
        height: $x-iphone-height;
      }
      &-arr {
        flex: 0 0 auto;
        position: relative;
        width: calc(799px * 0.1);
        height: calc(480px * 0.1);
        opacity: 1;
      }
    }
  }

}

.footer {
  padding: 24px 24px;
  background-color: var(--primaryColor);
  &-bottom {
    display: flex;
    flex-direction: row;
    gap: 24px;
    @media screen and (max-width: $x-breakpoint-xs) {
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }  
    &-cell {
      flex: 0 0 auto;
      color: var(--primaryText, "#FFFFFF") !important;
      text-decoration: none !important;
    }
    &-stretch {
      flex: 1 1 auto;
    }
  }
}